@font-face {
  font-family: "Pathfinder-Font";
  src: url("../fonts/Pathfinder2eActions.ttf");
}

action {
  font-weight: bold;
  font-style: italic;
}

feat {
  font-weight: bold;
  text-transform: capitalize;
}

item {
  font-weight: bold;
}

skill {
  font-weight: bold;
}

spell {
  font-style: italic;
}

.pf2-symbol {
  font-family: "Pathfinder-Font";
  font-size: 120%;
  vertical-align: 0%;
}

.pathfinder-back {
  background-color: #eeeeee;
  background-image: url("../images/background.jpg");
  border: 0.1rem solid black;
}

.pathfinder-block {
  padding: 1rem 1rem;
}

.pathfinder-block hr {
  margin: 0.25rem 0 0.25rem 0;
}

.pathfinder-block h3 {
  text-transform: uppercase;
  font-size: 1.4rem;
  padding-top: 0.1rem;
  font-weight: bold;
  font-style: normal;
  margin: 0;
  display: inline-block;
}

.pathfinder-block .title-line {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.pathfinder-block ul {
  list-style-type: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.pathfinder-block p {
  margin: 0.25rem 0 0 0;
  padding: 0;
}

.pathfinder-block .content {
  margin-top: 0.5rem;
  padding: 0;
}

div.pathfinder-block action,
div.pathfinder-block feat,
div.pathfinder-block item,
div.pathfinder-block skill,
div.pathfinder-block spell {
  font-weight: inherit;
  font-style: inherit;
  text-transform: inherit;
}

div.pathfinder-block .creature.free-text {
  text-indent: -1rem;
  padding-left: 1rem;
}
div.pathfinder-block .free-text spell {
  text-transform: lowercase;
  font-style: italic;
}
