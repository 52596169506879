$font-stack:    Trebuchet MS, Ariel, sans-serif;

@import 'figures';

body {
    font: 100% $font-stack;
    margin: 0;
}

h1 {
    font-variant: small-caps;
    margin: 1rem 0 0 0;
}

subtitle {
    display: block;
    margin: 0 0 1rem 0;
    text-align: center;
    text-transform: uppercase;
}

nav {
    background-color: black;
    padding: 0rem;
    margin: 0rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

}

nav a {
    padding: 1rem 2rem;
    color: white;
}

nav a:hover {
    background-color: white;
    color: black;
}

.current {    
    color: lightgreen;
}

.current:hover {
    background-color: lightgreen;
    color: black;
}

ul {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
}


.mainframe {
    max-width: 1000px;
    margin: 0 auto 2.5rem auto;
    padding: 0 1.0rem;
}

.mainframe a {
    color: black;
}

.mainframe a:hover {
    color: lightgreen;
    background-color:black;
}

.post-summary h3 {
    font-size: 1.2rem;
    margin: .5rem 0 0 0;
    text-transform: uppercase;
}


.post-summary p {
    margin: .25rem .25rem .5rem 1.5rem;
}

a {
    text-decoration: none;
}

h1 {
    text-align:center;
}

.post-list {
    list-style-type: none;
}

blockquote {
    font-family:'Courier New', Courier, monospace;
}

